<template>
  <div>
    <el-form>
      <el-row :gutter="50">
        <el-col :span="24">
          <div class="form-group">
            <el-form-item label="Content">
              <el-input
                type="textarea"
                v-model="field.content"
                placeholder="Enter content"
                rows="1"
                cols="20"
                :readOnly="true"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        
         
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name:"templates-formComponents-DIV",
       components: {
    
  },
  props: ["field"],
  data() {
    return {
      content: "<div></div>"
    };
  },
  mounted() {
    
    this.field.content = this.content
  }
};
</script>

<style lang="scss">
</style>